import React, { useEffect, useRef, useState } from 'react';
import './Info.css'; // Import your CSS file for styling
import tally_icon from '../images/tally_icon.png';

const Info = () => {
    const bubblesRef = useRef([]);
    const [cutoffIndex, setCutoffIndex] = useState(null);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const checkBubbles = () => {
        const container = bubblesRef.current[0]?.parentElement;
        if (container) {
            const containerRect = container.getBoundingClientRect();
            for (let i = 0; i < bubblesRef.current.length; i++) {
                const bubbleRect = bubblesRef.current[i].getBoundingClientRect();
                if (bubbleRect.right > containerRect.right) {
                    setCutoffIndex(i);
                    return;
                }
            }
            setCutoffIndex(null);
        }
    };

    useEffect(() => {
        const container = bubblesRef.current[0]?.parentElement;
        if (container) {
            container.addEventListener('scroll', checkBubbles);
            checkBubbles(); // Initial check
            return () => container.removeEventListener('scroll', checkBubbles);
        }
    }, []);

    return (
        <div className="info-page">
            <div className="info-details">
                <div className="page-name">Prompts</div>
                <div className="info-underline" />
                <div className="bubbles-container">
                    {["Paying", "Splitting", "Check Balance", "Edit Transactions", "Reminders", "Repeated Expenses"].map((text, index) => (
                        <div
                            key={text}
                            ref={el => bubblesRef.current[index] = el}
                            className={`bubble ${index === cutoffIndex ? 'cutoff' : ''}`}
                            onClick={() => scrollToSection(text.toLowerCase())}
                        >
                            {text}
                        </div>
                    ))}
                </div>
                <div className="text-instructions">
                    Tally uses keywords to perform tasks. <br /> Scroll down to see some examples.
                </div>
                <div className="imessage-container">
                    <div className="category-title" id="paying">Paying:</div>
                    <div className="imessage-bubble-container">
                        <div className="imessage-bubble">I <b>spent</b> 5 dollars on snacks</div>
                    </div>
                    <div className="imessage-or">or</div>
                    <div className="imessage-bubble-container">
                        <div className="imessage-bubble">I <b>paid</b> $5 for snacks</div>
                    </div>
					<div className="separator"></div>
                    <div className="category-title" id="splitting">Splitting:</div>
                    <div className="imessage-bubble-container">
                        <div className="imessage-bubble">Hey <b>Tally</b>, please <b>split</b> our pool</div>
                    </div>
                    <div className="imessage-or">or</div>
                    <div className="imessage-bubble-container">
                        <div className="imessage-bubble"><b>Settle up, Tally</b></div>
                    </div>
                </div>
                <div className="response-message-container">
                    <img className="response-logo" alt="Tally" src={tally_icon} />
                    <div className="text-response">
                        <div className="response-title">Tally</div>
                        <div className="response-message">
                            Sure! Here’s who owes who:<br />
                            John owes Ringo: $100.25<br />
                            John owes George: $120.35<br />
                            Ringo owes George: $20.00<br />
                            George doesn’t owe anything.
                        </div>
                    </div>
                </div>
				<div className="separator"></div>
                <div className="imessage-container">
                    <div className="category-title" id="check balance">Balance:</div>
                    <div className="imessage-bubble-container">
                        <div className="imessage-bubble"><b>Tally,</b> check my balance</div>
                    </div>
                    <div className="imessage-or">or</div>
                    <div className="imessage-bubble-container">
                        <div className="imessage-bubble"><b>Tally,</b> what's the total?</div>
                    </div>
                </div>
                <div className="response-message-container">
                    <img className="response-logo" alt="Tally" src={tally_icon} />
                    <div className="text-response">
                        <div className="response-title">Tally</div>
                        <div className="response-message">
                            Sure! Here's the balance: <br />
                            Total pool = $100.00<br />
                            John paid $20.00<br />
                            Paul paid $30.00<br />
                            George paid $50.00
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
