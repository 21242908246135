import "./style.css";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import TransactionsList from './TransactionsList';
import tally_logo from '../images/tally_logo.png'

function GroupDetails() {
	const { url_identifier } = useParams();
	const [groupId, setGroupId] = useState(null);
	const [poolId, setPoolId] = useState(null);
	const [poolBalance, setPoolBalance] = useState(null);
	const [poolName, setPoolName] = useState(null);
	const [transactionsList, setTransactionsList] = useState(null);
	const [participantList, setParticipantList] = useState(null);
	const [isEditingPoolName, setIsEditingPoolName] = useState(false);
	const inputRef = useRef(null);

	// const [editableItemValue, setEditableItemValue] = useState('');

	const baseUrl = 'https://tally-backend-dot-tough-shard-420717.uc.r.appspot.com'
	// const baseUrl = 'http://127.0.0.1:8080'

	const fetchUrlMapping = async () => {
		console.log('trying to fetch pool & group for url_identifier')
		try {
			const response = await fetch(baseUrl + '/url_mapping/' + url_identifier);
			if (!response.ok) {
			  throw new Error('Failed to fetch group & pool for url');
			}
			const data = await response.json();
			console.log(data.group_id)
			console.log(data.pool_id)
			setGroupId(data.group_id)
			setPoolId(data.pool_id)
		  } catch (error) {
			console.error('Error fetching group & pool for url:', error);
		  }
		};


	// 	console.log('trying to fetch transactions')
	// 	try {
	// 	  const response = await fetch(baseUrl + '/groups/' + group_id);
	// 	  if (!response.ok) {
	// 		throw new Error('Failed to fetch current time');
	// 	  }
	// 	  const data = await response.json();
	// 	  console.log(data.participant_data)
	// 	  console.log(data.current_pool_balance)
	// 	  setPoolBalance(data.current_pool_balance)
	// 	  setPoolName(data.pool_data.name)
	// 	  setTransactionsList(data.transaction_data);
	// 	  console.log(transactionsList)
	// 	  setParticipantList(data.participant_data)
	// 	} catch (error) {
	// 	  console.error('Error fetching current time:', error);
	// 	}
	//   };

	  useEffect(() => {
		fetchUrlMapping();
	  }, []); 

	  useEffect(() => {
		const fetchGroupDetails = async () => {
		  if (groupId && poolId) {
			console.log('Trying to fetch transactions');
			try {
			  const response = await fetch(baseUrl + '/groups/' + groupId);
			  if (!response.ok) {
				throw new Error('Failed to fetch group details');
			  }
			  const data = await response.json();
			  console.log(data.participant_data);
			  console.log(data.current_pool_balance);
			  setPoolBalance(data.current_pool_balance);
			  setPoolName(data.pool_data.name);
			  setTransactionsList(data.transaction_data);
			  console.log(transactionsList);
			  setParticipantList(data.participant_data);
			} catch (error) {
			  console.error('Error fetching group details:', error);
			}
		  }
		};
	
		fetchGroupDetails();
	  }, [groupId, poolId]); // Triggered whenever group_id or pool_id changes

	    // Callback function to update pool balance when transaction value changes
  const updatePoolBalance = (diff) => {
    // Logic to update pool balance based on newValue
    // Example:
    const updatedPoolBalance = poolBalance + diff; // Example logic, adjust as per your application
    setPoolBalance(updatedPoolBalance);
  };

  const updatePoolName = async (pool_name) => {
	// console.log('trying to update item name')
	// console.log(transaction_id)
	// console.log(itemName)
	if (pool_name == null) {
		pool_name = ""
	}
	try {
		const requestOptions = {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json', // Specify the content type as JSON
			},
			body: JSON.stringify({
			  // Include any data you want to send in the request body
			  // For example:
			  'pool_id': poolId, 
			  'group_id': groupId,
			  'pool_name': pool_name, // Replace with your actual data
			//   'transaction_id': transaction_id,
			}),
		  };

	  const response = await fetch(baseUrl + '/update_pool_name/', requestOptions);
	  if (!response.ok) {
		throw new Error('Failed to update pool name');
	  }
	} catch (error) {
	  console.error('Error updating pool name:', error);
	}
  };


  const handlePoolNameChange = (event) => {
    // Update local state as the user types
    setPoolName(event.target.value);
  };

  const handlePoolNameBlur = () => {
	updatePoolName(poolName).then(() => {
		// Reset editable state
		console.log('updated pool name to ', poolName)
	})
	setIsEditingPoolName(false);
  };

  const handlePoolNameClick = () => {
    setIsEditingPoolName(true);
  };

  const handleFocus = () => {
    // Use setTimeout to ensure the ref is set
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, 0);
  };
	
  return (
    <div className="iphone-pro">
        {/* <img className="tally-logo" alt="Tally" src={tally_logo} /> */}
		<div className="group-details">
			<div>
				<div className="group-name">
					{isEditingPoolName ? (
						<input
						className='pool-name-edit-box'
						type="text"
						value={poolName}
						onChange={handlePoolNameChange}
						onBlur={handlePoolNameBlur}
						autoFocus
						onFocus={handleFocus}
						ref={inputRef}
						/>
					) : (
						<div>
						<span onClick={handlePoolNameClick}>
							{poolName}
						</span>
						<div className="group-underline" />  
						</div>
					)}
				</div>
				{/* <div className="group-name">{poolName}</div> */}
			</div>  
		  {poolBalance && 
		  	<div className="pool-balance">${parseFloat(poolBalance).toFixed(2)}</div>
			}
		  {transactionsList ? (
			<TransactionsList
				initialTransactions={transactionsList}
				participants={participantList}
				pool_id={poolId}
				group_id={groupId}
				updatePoolBalance={updatePoolBalance} // Pass the callback function
			/>
        ) : (
          <p>Loading transactions...</p>
        )}
		{/* <TransactionsList initialTransactions={transactionsList} participants={participantList} pool_id={pool_id} group_id={group_id}/> */}
      </div>
    </div>
  );
  }

export default GroupDetails;
