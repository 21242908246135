// App.js

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import GroupDetails from './components/GroupDetails';
import Info from './components/Info';
import NavBar from './components/NavBar';
import './App.css'; // Import your CSS file

const App = () => {
	return (
	  <Router>
		<div className="app-container">
		  <NavBar />
		  <div className="main-content">
			<Routes>
			  <Route path="/" element={<Home />} />
			  <Route path="/group/:url_identifier" element={<GroupDetails />} />
			  <Route path="/info" element={<Info />} />
			</Routes>
		  </div>
		</div>
	  </Router>
	);
  };

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<div><NavBar/><Home/></div>} />
//         <Route path="/group/:url_identifier" element={<div><NavBar/><GroupDetails/></div>} />
// 		<Route path="/info" element={<div><NavBar/><Info/></div>} />
//       </Routes>
//     </Router>
//   );
// };

export default App;