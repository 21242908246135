

import React, { useState, useEffect, useRef } from 'react';
import trash_icon from '../images/trash_icon.png'


function TransactionsList({ initialTransactions, participants, group_id, pool_id, updatePoolBalance}) {
    const [transactions, setTransactions] = useState(initialTransactions); // Added state for transactions
  const [swipedIndex, setSwipedIndex] = useState(null);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [showBox, setShowBox] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [selectedPaidBy, setSelectedPaidBy] = useState(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [showTriangle, setShowTriangle] = useState(null);
  const [selectedSplitBy, setSelectedSplitBy] = useState(null);
  const [trianglePosition, setTrianglePosition] = useState({ top: 0, left: 0 });
  const [paidByPopupPosition, setPaidByPopupPosition] = useState({ top: 0, left: 0 });
  const [splitByPopupPosition, setSplitByPopupPosition] = useState({ top: 0, left: 0 });
  const [trashIconPosition, setTrashIconPosition] = useState({ top: 0 });
  const [paidByPopupLayoutClass, setPaidByPopupLayoutClass] = useState('horizontal-stack');
  const [splitByPopupLayoutClass, setSplitByPopupLayoutClass] = useState('horizontal-stack');
  const [originalAmount, setOriginalAmount] = useState(null);
  const inputRef = useRef(null);

// edit transaction amount
  const [editableAmount, setEditableAmount] = useState(null); 
  const [editableAmountValue, setEditableAmountValue] = useState(''); 

  // edit transaction item name
  const [editableItem, setEditableItem] = useState(null); 
  const [editableItemValue, setEditableItemValue] = useState('');


  const baseUrl = 'https://tally-backend-dot-tough-shard-420717.uc.r.appspot.com'
// const baseUrl = 'http://127.0.0.1:8080'

const deleteTransaction = async (transaction_id, amount) => {
    console.log('trying to delete transaction (id ' + transaction_id + ')')
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json', // Specify the content type as JSON
            },
            body: JSON.stringify({
                // Include any data you want to send in the request body
                // For example:
                'pool_id': pool_id, 
                'group_id': group_id,
                'transaction_id': transaction_id,
              }),
          };

      const response = await fetch(baseUrl + '/delete_transaction/', requestOptions);
      if (!response.ok) {
        throw new Error('Failed to delete transaction (id ' + transaction_id + ')');
      }

        console.log("deleting amount " + amount)
        const deletedAmount = -1 * amount
        updatePoolBalance(deletedAmount); // Update balance with negative amount to subtract
        
    } catch (error) {
      console.error('Error deleting transaction:', error);
    }
  };

  const updateItemNameDB = async (transaction_id, itemName) => {
    console.log('trying to update item name')
    console.log(transaction_id)
    console.log(itemName)
    if (itemName == null) {
        itemName = ""
    }
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Specify the content type as JSON
            },
            body: JSON.stringify({
              // Include any data you want to send in the request body
              // For example:
              'pool_id': pool_id, 
              'group_id': group_id,
              'item_name': itemName, // Replace with your actual data
              'transaction_id': transaction_id,
            }),
          };

      const response = await fetch(baseUrl + '/update_transaction_item_name/', requestOptions);
      if (!response.ok) {
        throw new Error('Failed to update item name');
      }
    } catch (error) {
      console.error('Error updating item name:', error);
    }
  };

  const updateAmountDB = async (transaction_id, amount) => {
    console.log('trying to update amount')
    console.log(transaction_id)
    console.log(amount)
    if (amount == null) {
        amount = 0
    }
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Specify the content type as JSON
            },
            body: JSON.stringify({
              // Include any data you want to send in the request body
              // For example:
              'pool_id': pool_id, 
              'group_id': group_id,
              'amount': amount, // Replace with your actual data
              'transaction_id': transaction_id,
            }),
          };

      const response = await fetch(baseUrl + '/update_transaction_amount/', requestOptions);
      if (!response.ok) {
        throw new Error('Failed to update amount');
      }
    } catch (error) {
      console.error('Error updating amount:', error);
    }
  };

  const updateSplitByDB = async (transaction_id, splitByArray) => {
    console.log('trying to update split by')
    console.log(transaction_id)
    console.log(splitByArray)
    if (splitByArray == null) {
        splitByArray = []
    }
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Specify the content type as JSON
            },
            body: JSON.stringify({
              // Include any data you want to send in the request body
              // For example:
              'pool_id': pool_id, 
              'group_id': group_id,
              'split_by_map': splitByArray, // Replace with your actual data
              'transaction_id': transaction_id,
            }),
          };

      const response = await fetch(baseUrl + '/update_transaction_split_by/', requestOptions);
      if (!response.ok) {
        throw new Error('Failed to update split by');
      }
    } catch (error) {
      console.error('Error updating split by:', error);
    }
  };

  const updatePaidByDB = async (transaction_id, paid_by) => {
    console.log(paid_by)
    console.log(pool_id)
    console.log(group_id)
    console.log(transaction_id)
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
              'pool_id': pool_id, 
              'group_id': group_id,
              'paid_by': paid_by, 
              'transaction_id': transaction_id,
            }),
          };

          
      const response = await fetch(baseUrl + '/update_transaction_paid_by/', requestOptions);
      if (!response.ok) {
        throw new Error('Failed to update paid by');
      }
    } catch (error) {
      console.error('Error updating paid by:', error);
    }
  };

  useEffect(() => {
    if (selectedPaidBy !== null) {
      // Perform actions that depend on selectedPaidBy being updated
    //   console.log('selectedPaidBy updated:', selectedPaidBy);
      // Example: Call updatePaidByDB here
    }
    console.log('selectedPaidBy updated:', selectedPaidBy);
  }, [selectedPaidBy]);

  useEffect(() => {
    // This code will run only once when the component mounts
    console.log("Component mounted");
    console.log(initialTransactions)
    console.log(transactions)

    // You can perform any initialization tasks here

    return () => {
        // Cleanup code (if needed) when the component unmounts
    };
}, []); // Empty dependency array ensures that this effect runs only once



const handleItemClick = (transaction, item) => {
    setSelectedTransactionId(transaction[0]);
    // Enable editing by setting the editable amount state
    setEditableItem(transaction[0]);
    // Initialize editable value with the current amount
    setEditableItemValue(item); // Assuming amount is a number
  };

  const handleItemChange = (event) => {
    // Update local state as the user types
    setEditableItemValue(event.target.value);
  };

  const handleItemBlur = (transactionId) => {
    // Save the edited amount to the transactions state
    setTransactions((prevTransactions) =>
      prevTransactions.map((transaction) =>
        transaction[0] === transactionId
          ? { ...transaction, [1]: { ...transaction[1], item: editableItemValue } }
          : transaction
      )
    );

    updateItemNameDB(selectedTransactionId, editableItemValue).then(() => {
        // Reset editable state
        setEditableItem(null);
        console.log('success')
    })
  };


const handleAmountClick = (transaction, amount) => {
    setSelectedTransactionId(transaction[0]);
    // Enable editing by setting the editable amount state
    setEditableAmount(transaction[0]);

    setOriginalAmount(parseFloat(amount).toFixed(2));

    // Initialize editable value with the current amount
    setEditableAmountValue(parseFloat(amount).toFixed(2)); // Assuming amount is a number
  };

  const handleAmountChange = (event) => {
    // Update local state as the user types
    setEditableAmountValue(event.target.value);
  };

  const handleAmountBlur = (transactionId) => {
    const newAmount = parseFloat(editableAmountValue)
    const diff = newAmount - originalAmount
    // Save the edited amount to the transactions state
    setTransactions((prevTransactions) =>
      prevTransactions.map((transaction) =>
        transaction[0] === transactionId
          ? { ...transaction, [1]: { ...transaction[1], amount: newAmount } }
          : transaction
      )
    );

    updatePoolBalance(diff)

    updateAmountDB(selectedTransactionId, editableAmountValue).then(() => {
        // Reset editable state
        setEditableAmount(null);
        console.log('success')
    })
  };



  const handlePaidByPopupClick = (participant) => {
    // Update the selectedPaidBy state to the clicked participant
    setSelectedPaidBy(participant);
    console.log(participant)
    console.log('in popup click', selectedTransactionId)

    // Update the transaction's paid_by field in the transactions state
    setTransactions((prevTransactions) =>
    prevTransactions.map((transaction) =>
      transaction[0] === selectedTransactionId
        ? { ...transaction, [1]: { ...transaction[1], paid_by: participant } }
        : transaction
    )
    );

    updatePaidByDB(selectedTransactionId, participant).then(() => {
    //   setSelectedPaidBy(null);
    //   setShowTriangle(false);
        console.log('success')
        // setSelectedPaidBy(null);
        setShowTriangle(false)
    })
    .catch((error) => {
        console.log('failure')
        console.error('Error updating database:', error);
    })


    // Trigger custom logic inside the onClick function
    // Add your custom logic here based on the clicked participant
    console.log(`Clicked participant: ${participant}`);

    // TODO add db call to update paid by person
  };

  const handleSplitByPopupClick = (participant) => {
    console.log(participant)
    // const new_map = new Map(selectedSplitBy);
    var tempArr = selectedSplitBy
    if(selectedSplitBy.includes(participant)) {
        tempArr = tempArr.filter(element => element !== participant);
    } else {
        tempArr.push(participant)
    }
    // new_map.set(participant[1].number, !selectedSplitBy.get(participant[1].number))
    setSelectedSplitBy(tempArr);

    // DB update
    updateSplitByDB(selectedTransactionId, tempArr).then(() => {
        console.log('success')
        // setSelectedPaidBy(null);
        // setShowTriangle(false)
    })
    .catch((error) => {
        console.log('failure')
        console.error('Error updating database:', error);
    })

    // Trigger custom logic inside the onClick function
    // Add your custom logic here based on the clicked participant
    console.log(`Clicked participant: ${participant}`);

    console.log(selectedSplitBy)

    // Update the transaction's paid_by field in the transactions state
    setTransactions((prevTransactions) =>
    prevTransactions.map((transaction) =>
        transaction[0] === selectedTransactionId
        ? { ...transaction, [1]: { ...transaction[1], split_by: tempArr } }
        : transaction
    )
        );

    // TODO add db call to update paid by person
  };

  const handlePaidByClick = (transaction, event) => {
    setSelectedTransactionId(transaction[0]);
    setSelectedPaidBy(transaction[1].paid_by);
  
    // Get the clicked element's bounding rectangle
    const rect = event.target.getBoundingClientRect();
  
    // Calculate the center position relative to the viewport
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
  
    setTrianglePosition({ top: centerY + 8, left: centerX });
    setShowTriangle(true);
  
    // Determine layout based on available width
    const participantWidth = 110; // Approximate width of each participant item (10 digits + some padding)
    const participantHeight = 10;
    // const popupWidth = paidByPopupRef.current?.clientWidth || 0;
    // const popupHeight = paidByPopupRef.current?.clientHeight || 0;
    // console.log(popupHeight + " " + popupWidth)

    let layoutClass = '';

    let left = centerX - (participants.length * participantWidth) / 2;
    let top = centerY + 20;
    const { longestString, totalCharacters } = participants.reduce((acc, participant) => {
        // Calculate total characters
        acc.totalCharacters += participant.length;
      
        // Find longest string
        if (participant.length > acc.longestString) {
          acc.longestString = participant.length;
        }
      
        return acc;
      }, { longestString: "", totalCharacters: 0 });
    let popupWidth = 0
    console.log("longest string " + longestString)

    if (participants.length * participantWidth > window.innerWidth) {
      layoutClass = 'vertical-stack';
      console.log('choosing vertical stack')

       left = centerX - (participantWidth / 2);
       popupWidth = longestString * 10 
        // Ensure the popup is fully visible on the screen vertically
        if (top + (participants.length * participantHeight) > window.innerHeight) {
            top = centerY - 20 - (participants.length * participantHeight); // Place the popup above the clicked element if it overflows
        }
    } else {
        layoutClass = 'horizontal-stack';
        console.log('choosing horizontal stack')

        // popupWidth = participants.length * participantWidth
        popupWidth = totalCharacters * 10
    }
  
    console.log(left + " " + popupWidth + " " + window.innerWidth)
    // Ensure the popup is fully visible on the screen horizontally
    if (left < 0) {
        left = 10; // Adding a small margin from the left edge
    } else if (left + popupWidth + 30 > window.innerWidth) {
        console.log('will overflow width')
        left = window.innerWidth - (popupWidth) - 50; // Adding a small margin from the right edge
        console.log('new left ' + left)
    }

  
    console.log("top" + " left" + top + " " + left)
    setPaidByPopupPosition({ top: top, left: left });
    setPaidByPopupLayoutClass(layoutClass);
  };

//   const handlePaidByClick = (transaction, event) => {
//  setSelectedTransactionId(transaction[0])
//  // setSelectedTransaction(transaction)
//     setSelectedPaidBy(transaction[1].paid_by);
//  // setClickedRow(index);

//  // Get the clicked element's bounding rectangle
//  const rect = event.target.getBoundingClientRect();

//  // Calculate the center position relative to the viewport
//  const centerX = rect.left + rect.width / 2;
//  const centerY = rect.top + rect.height / 2;

//  setTrianglePosition({ top: centerY + 8, left: centerX })
//  setShowTriangle(true)


//     const popupWidth = paidByPopupRef.current?.clientWidth || 0;
//     const left = Math.min(centerX - popupWidth / 2, window.innerWidth - popupWidth - 150);



//  console.log(centerX, centerY)
//  setPaidByPopupPosition({ top: centerY + 20, left: left });
//   };
  
  // split by logic: 
  // if split by is null, then it's split by everyone
  // if split by isn't null, then go through ppl in the map
  // if local list == length of participants, then set it to null (signifying that its split by everyone again)

  const handleSplitByClick = (transaction, event) => {
    setSelectedTransactionId(transaction[0]);
    setSelectedSplitBy(transaction[1].split_by)
  
    // Get the clicked element's bounding rectangle
    const rect = event.target.getBoundingClientRect();
  
    // Calculate the center position relative to the viewport
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
  
    setTrianglePosition({ top: centerY + 8, left: centerX });
    setShowTriangle(true);
  
    // Determine layout based on available width
    const participantWidth = 110; // Approximate width of each participant item (10 digits + some padding)
    const participantHeight = 10;
    // const popupWidth = splitByPopupRef.current?.clientWidth || 0;
    // const popupHeight = splitByPopupRef.current?.clientHeight || 0;
  
    let layoutClass = '';
  
    let left = centerX - (participants.length * participantWidth) / 2;
    let top = centerY + 20;
    const { longestString, totalCharacters } = participants.reduce((acc, participant) => {
        // Calculate total characters
        acc.totalCharacters += participant.length;
      
        // Find longest string
        if (participant.length > acc.longestString) {
          acc.longestString = participant.length;
        }
      
        return acc;
      }, { longestString: "", totalCharacters: 0 });
    let popupWidth = 0
    console.log("longest string " + longestString)

    if (participants.length * participantWidth > window.innerWidth) {
      layoutClass = 'vertical-stack';
      console.log('choosing vertical stack')

       left = centerX - (participantWidth / 2);
       popupWidth = longestString * 10 
        // Ensure the popup is fully visible on the screen vertically
        if (top + (participants.length * participantHeight) > window.innerHeight) {
            top = centerY - 20 - (participants.length * participantHeight); // Place the popup above the clicked element if it overflows
        }
    } else {
        layoutClass = 'horizontal-stack';
        console.log('choosing horizontal stack')

        // popupWidth = participants.length * participantWidth
        popupWidth = totalCharacters * 10
    }
  
    console.log(left + " " + popupWidth + " " + window.innerWidth)
    // Ensure the popup is fully visible on the screen horizontally
    if (left < 0) {
        left = 10; // Adding a small margin from the left edge
    } else if (left + popupWidth + 30 > window.innerWidth) {
        console.log('will overflow width')
        left = window.innerWidth - (popupWidth) - 50; // Adding a small margin from the right edge
        console.log('new left ' + left)
    }
  
    setSplitByPopupPosition({ top: top, left: left });
    setSplitByPopupLayoutClass(layoutClass);
  };


const handleClosePaidByPopup = () => {
    // update DB
    // console.log(selectedTransactionId)
    // var paidByCopy = new Map(selectedPaidBy)
    // console.log(selectedPaidBy)
    // console.log(paidByCopy)
    console.log("in handle close", selectedPaidBy)

    // updatePaidByDB(selectedTransactionId, selectedPaidBy).then(() => {
    // //   setSelectedPaidBy(null);
    // //   setShowTriangle(false);
    //  console.log('success')
    //  setSelectedPaidBy(null);
    //  setShowTriangle(false)
    // })
    // .catch((error) => {
    //  console.log('failure')
    //   console.error('Error updating database:', error);
    // })

    // updatePaidByDB(selectedTransactionId, selectedPaidBy)

    setSelectedPaidBy(null);
    setShowTriangle(false);

  };


  const handleCloseSplitByPopup = () => {
    // update DB
    // updateSplitByDB(selectedTransactionId, selectedSplitBy)

    setSelectedSplitBy(null);
    setShowTriangle(false)
  };


  const paidByPopupRef = useRef(null); // Ref to the popup element
  const splitByPopupRef = useRef(null); // Ref to the popup element


  // UseEffect hook to add click event listener to document
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the popup if click occurs outside of the popup content
      if (
        paidByPopupRef.current &&
        !paidByPopupRef.current.contains(event.target) &&
        event.target.className !== 'transaction-paid-by' // Check if the clicked element is not the transaction details button
      ) {
        console.log('before calling close paid by popup', selectedPaidBy)
        handleClosePaidByPopup();
      }
    };

    // Add event listener to handle clicks outside of the popup
    document.addEventListener('click', handleClickOutside);

    // Clean up by removing event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [paidByPopupRef]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the popup if click occurs outside of the popup content
      if (
        splitByPopupRef.current &&
        !splitByPopupRef.current.contains(event.target) &&
        event.target.className !== 'transaction-split-by' 
      ) {
        handleCloseSplitByPopup();
      }
    };

    // Add event listener to handle clicks outside of the popup
    document.addEventListener('click', handleClickOutside);

    // Clean up by removing event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [splitByPopupRef]);

  function getSplitByLabel(splitBy, participants) {
    if (!splitBy || splitBy === "null" || splitBy.length === 0 || splitBy.length === participants.length) {
      return "everyone";
    } else if (splitBy.length > 1 && splitBy.length < participants.length) {
      return "multiple";
    } else {
      return splitBy.join();
    }
  }
  


  const handleTouchStart = (index, event) => {
    setSwipedIndex(index);
    const touch = event.touches[0];
    setStartX(touch.clientX);
    setCurrentX(touch.clientX);

    const rowRect = event.currentTarget.getBoundingClientRect();
    setTrashIconPosition({ top: rowRect.top + window.scrollY + rowRect.height / 2 - 15 });
  };

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    setCurrentX(touch.clientX);
  };

  function displayPaidBy(paidBy) {
    if (isPhoneNumber(paidBy)) {
      return formatPhoneNumber(paidBy);
    } else {
      return paidBy; // Display original text if not a phone number
    }
  }
  
  function isPhoneNumber(value) {
    // Regular expression to match phone numbers with optional country code
    return /^\+?\d{10,14}$/.test(value);
  }
  
  function formatPhoneNumber(phoneNumber) {
    // Example: Format phone number to show last 4 digits
    return phoneNumber.slice(-4);
  }
  
  
    function isPhoneNumber(value) {
        // Regular expression to match phone numbers with optional country code
        return /^\+?\d{10,14}$/.test(value);
    }

  function formatPhoneNumber(phoneNumber) {
    return phoneNumber.slice(-4); // Display last 4 digits of phone number
  }  

  const handleTouchEnd = (transaction, index, event) => {
    if (swipedIndex !== null) {
      const deltaX = currentX - startX;
      if (deltaX < -200) {
        // User swiped left, perform delete action
        console.log('Swiped left on index ' + swipedIndex + ' with id ' + transaction[0]);
        console.log(transaction[0])
        // deleteTransaction(transaction[0])

        deleteTransaction(transaction[0], transaction[1].amount).then(() => {
                console.log('success')
                setTransactions((prevTransactions) => prevTransactions.filter((_, i) => i !== index));
            })
            .catch((error) => {
                console.log('failure')
                console.error('Error updating database:', error);
            })

      }
      setSwipedIndex(null);
    }
    setCurrentX(0);
  };

  const handleFocus = () => {
    // Use setTimeout to ensure the ref is set
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, 0);
  };

    if (transactions) {
  return (
    <div className="transactions-list" style={{ width: '100%', overflowX: 'hidden' }}>
      {transactions
        .sort((a, b) => new Date(b[1].date) - new Date(a[1].date))
        .map((transaction, index) => (
        <div>
        <div 
            key={index}
            // className="transaction-row"
            className={`transaction-row ${swipedIndex === index ? 'swiped' : ''}`}
            style={{
                transform: `translateX(${swipedIndex === index ? currentX - startX : 0}px)`,
                transition: 'transform 0.2s ease',
            }}
            // style={{ transform: `translateX(${swipedIndex === index ? currentX - startX : 0}px)`, transition: 'transform 0.2s ease' }}
            onTouchStart={(event) => handleTouchStart(index, event)}
            onTouchMove={handleTouchMove}
            onTouchEnd={(event) => handleTouchEnd(transaction, index, event)}
        >
            <div key={index} className="transaction-group">
                <div className="transaction-date">
                    {getFormattedDate(transaction[1].date)}
                    </div>
                    <div className="transaction-divider" />
                    <div className="transaction-body">
                        {/* <div className="transaction-item-amount">
                            <div className="transaction-item">{transaction[1].item}</div>
                            <div className="transaction-amount">${transaction[1].amount.toFixed(2)}</div>
                        </div> */}


                        <div className="transaction-item-amount">
                            <div className="transaction-item" onClick={() => handleItemClick(transaction, transaction[1].item)}>
                                {editableItem === transaction[0] ? (
                                <input
                                    className='edit-box'
                                    type="text"
                                    value={editableItemValue}
                                    onChange={handleItemChange}
                                    onBlur={() => handleItemBlur(transaction[0])}
									onFocus={handleFocus}
									ref={inputRef}
                                />
                                ) : (
                                <span>{transaction[1].item}</span>
                                )}
                            </div>

                            <div className="transaction-amount" onClick={() => handleAmountClick(transaction, transaction[1].amount)}>
                                {editableAmount === transaction[0] ? (
                                <input
                                    className='edit-box-right'
                                    type="number"
                                    value={editableAmountValue}
									step="0.01" // Allows decimal values
                                    onChange={handleAmountChange}
                                    onBlur={() => handleAmountBlur(transaction[0])}
									onFocus={handleFocus}
									ref={inputRef}
                                />
                                ) : (
                                // <span>${parseFloat(transaction[1].amount).toFixed(2)}</span>
                                <span>${parseFloat(transaction[1].amount) % 1 !== 0
                                    ? parseFloat(transaction[1].amount).toFixed(2)
                                    : parseFloat(transaction[1].amount).toFixed(0)}
                                </span>)}
                            </div>
                        </div>



                        <p className="transaction-text">
                            <span className="span">Paid by </span>
                            <span 
                                className="transaction-paid-by"
                                onClick={(e) => handlePaidByClick(transaction, e)}>
                                    {displayPaidBy(transaction[1].paid_by)}
                                    {/* {transaction[1].paid_by ? transaction[1].paid_by : "?"} */}
                            </span>
                            <span className="span"> split by </span>
                            <span className="transaction-split-by"
                                onClick={(e) => handleSplitByClick(transaction, e)}
                                >
                                    {getSplitByLabel(transaction[1].split_by, participants)}

                                    {/* {(transaction[1].split_by == null || transaction[1].split_by == "null" || transaction[1].split_by.length == 0 || transaction[1].split_by.length == participants.length) ? "everyone" : transaction[1].split_by.join()} */}
                            </span>
                        </p>
                        </div>
                    </div>
                    </div>
      

    {swipedIndex === index && currentX - startX < -50 && (
        <img
        src={trash_icon}
        alt="Delete"
        style={{
            position: 'absolute',
            // top: '50%',
            top: trashIconPosition.top + 3,
            right: '10px',
            zIndex: 1,
            height: '30px',
            display: swipedIndex !== null && currentX - startX < -50 ? 'block' : 'none',
            // transform: 'translateY(-50%)',
            cursor: 'pointer',
        }}
        onClick={() => {
            // Handle row deletion here
            console.log('Delete transaction at index', index);
        }}
        />
    )}

    </div>
      
      ))}

      {showTriangle && <div style={{ position: 'absolute', top: trianglePosition.top, left: trianglePosition.left }} className='triangle'/>}

      {selectedPaidBy && (
        <div ref={paidByPopupRef} 
        style={{ position: 'absolute', top: paidByPopupPosition.top, left: paidByPopupPosition.left }}>
            {/* <div className="blue-rectangle"> */}

            <div className={paidByPopupLayoutClass}>

            {participants.map((participant) => (
                <div 
                    className={selectedPaidBy == participant ? "participant-bold" : "participant-normal"}
                    key={participant}
                    onClick={() => handlePaidByPopupClick(participant)}
                    >
                        {participant}
                </div>
            ))}
            </div>
            </div>
      )}
      
      {selectedSplitBy && (
        <div ref={splitByPopupRef} style={{ position: 'absolute', top: splitByPopupPosition.top, left: splitByPopupPosition.left }}>
            <div className={paidByPopupLayoutClass}>
            {participants.map((participant) => (
            <div 
                className={selectedSplitBy.includes(participant) ? "participant-bold" : "participant-normal"}
                // className={selectedSplitBy.get(participant[1].number) ? "participant-bold" : "participant-normal"}
                key={participant}
                onClick={() => handleSplitByPopupClick(participant)}
                >
                    {participant}
            </div>
            ))}
            </div>
        </div>
      )}
    </div>
  )};
}

export default TransactionsList;

function getFormattedDate(dateString) {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  return (
    <>
      {month}
      <br />
      {day}
    </>
  );
}

