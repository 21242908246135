import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tally_logo from '../images/tally_logo.png';
import './style.css';

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (path) => {
    setMenuOpen(false);
    navigate(path);
  };

  return (
    <div>
      <div className="navbar">
        <img className="tally-logo" alt="Tally" src={tally_logo} />
        <div className="hamburger" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>

      {menuOpen && (
        <div className="full-screen-menu">
          <div className="menu-content">
            <div className="close-button" onClick={toggleMenu}>×</div>
            <ul>
				<li onClick={() => handleNavigation('/')}>Home</li>
				<li onClick={() => handleNavigation('/info')}>Input Guide</li>
				<li>Contact Us</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
